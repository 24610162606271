//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import * as am4plugins_timeline from '@amcharts/amcharts4/plugins/timeline';
import { mapGetters, mapActions } from 'vuex';
import eventBus from '@/plugins/eventBus';
import * as _ from 'underscore';

const momentTz = require('moment-timezone');

am4core.useTheme(am4themes_animated);

export default {
  name: 'behaviour',
  data() {
    return {
      showChart: true,
      showInnerLoading: false,
      isCall: false,
      sessions_data: [],
      timeUnit: 'second',
      Serpentine_Stepline_data: [],
      avgDuration: '00:00',
      totalDuration: '00:00',
      longestDuration: '00:00',
      shortestDuration: '00:00',
      noOfuserServed: 0,
      showChat: false,
      currentChatId: 0,
      showMessageLoading: false,
      chatObj: {},
    };
  },
  computed: {
    ...mapGetters({
      getFilterDate: 'getFilterDate',
      currentAIAccount: 'getCurrentAIAccount',
      currentUser: 'getCurrentUser',
      darkTheme: "getDarkTheme"
    }),
    baseURl() {
      return window.location.href;
    },
  },
  methods: {
    ...mapActions({
      requestAISessionsAction: 'requestAISessionsList',
      requestAISessionsPerDay: 'requestAISessionsPerDay',
      requestAITrendsSessions: 'requestAITrendsSessions',
      requestSpecificSession: 'requestSpecificSession',
    }),

    generateSerpentineChart() {
      const that = this
      if(that.chart){
        that.chart.dispose()
      }
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark)
      }
      const chart = am4core.create(this.$refs.serpentineChart, am4plugins_timeline.SerpentineChart);
      chart.curveContainer.padding(50, 20, 50, 20);
      chart.levelCount = 4;
      chart.yAxisRadius = am4core.percent(25);
      chart.yAxisInnerRadius = am4core.percent(-25);
      chart.maskBullets = true;

      const colorSet = new am4core.ColorSet();
      colorSet.saturation = 0.5;
      chart.data = that.Serpentine_data;

      chart.dateFormatter.dateFormat = 'yyyy-MM-dd-H-m-s';
      chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd-H-m-s';
      chart.fontSize = 11;

      const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'category';
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.labels.template.paddingRight = 25;
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.innerRadius = -60;
      categoryAxis.renderer.radius = 60;

      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 70;
      dateAxis.baseInterval = { count: 1, timeUnit: that.timeUnit };
      dateAxis.renderer.tooltipLocation = 0;
      dateAxis.startLocation = -0.5;
      dateAxis.renderer.line.strokeDasharray = '1,4';
      dateAxis.renderer.line.strokeOpacity = 0.6;
      dateAxis.tooltip.background.fillOpacity = 0.2;
      dateAxis.tooltip.background.cornerRadius = 5;
      dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor(
        'alternativeBackground',
      );
      dateAxis.tooltip.label.paddingTop = 7;

      const labelTemplate = dateAxis.renderer.labels.template;
      labelTemplate.verticalCenter = 'middle';
      labelTemplate.fillOpacity = 0.7;
      // labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor(
      //   'background',
      // );
      // labelTemplate.background.fillOpacity = 1;
      labelTemplate.padding(7, 7, 7, 7);

      const series = chart.series.push(new am4plugins_timeline.CurveColumnSeries());

      series.columns.template.height = am4core.percent(20);
      series.columns.template.tooltipText = '[bold]{startDTime} - [bold]{endDTime}[/]';

      series.dataFields.openDateX = 'start';
      series.dataFields.dateX = 'end';
      series.dataFields.categoryY = 'category';
      series.dataFields.id = 'id';
      series.columns.template.propertyFields.fill = 'color'; // get color from data
      series.columns.template.propertyFields.stroke = 'color';
      series.columns.template.strokeOpacity = 0;

      series.columns.template.events.on('hit', (ev) => {
        const nodeId = ev.target._dataItem.id;
        that.loadConversation(nodeId);
      }, this);

      const bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.radius = 3;
      bullet.circle.strokeOpacity = 0;
      bullet.propertyFields.fill = 'color';
      bullet.locationX = 0;
      bullet.events.on('hit', (ev) => {
        const nodeId = ev.target._dataItem.id;
        that.loadConversation(nodeId);
      }, this);

      bullet.events.on('over', (ev) => {
        bullet.tooltipText = '[bold]{startDTime} - [bold]{endDTime}[/]';
      }, this);

      const bullet2 = series.bullets.push(new am4charts.CircleBullet());
      bullet2.circle.radius = 3;
      bullet2.circle.strokeOpacity = 0;
      bullet2.propertyFields.fill = 'color';
      bullet2.locationX = 1;
      bullet2.events.on('hit', (ev) => {
        const nodeId = ev.target._dataItem.id;
        that.loadConversation(nodeId);
      }, this);
      bullet2.events.on('over', (ev) => {
        bullet2.tooltipText = '[bold]{startDTime} - [bold]{endDTime}[/]';
      }, this);
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.align = 'center';
      chart.scrollbarX.width = am4core.percent(85);

      dateAxis.renderer.tooltipLocation2 = 0;
      categoryAxis.cursorTooltipEnabled = false;
      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-User Activity Timeline-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;
      this.chart = chart;
    },
    loadConversation(nodeId) {
      const that = this;
      const data = {
        sessionId: nodeId,
        projectId: that.currentAIAccount.id,
      };
      that.showChat = true;
      that.currentChatId = nodeId;
      that.chatObj = { };
      that.showMessageLoading = true;
      that.requestSpecificSession(data).then((res) => {
        res.start_time = momentTz.tz(res.start_time, res.timeZone).format('DD MMM YYYY hh:mm:ss a');
        that.chatObj = res;
        that.updateCurrentSession();
      }).catch((err) => {
        that.showChart = false;
      });
    },
    closeChat() {
      const that = this;
      that.showChat = false;
      that.currentChatId = 0;
      that.chatObj = { };
    },

    updateCurrentSession() {
      const that = this;
      _.map(that.chatObj.messages, (message) => {
        message.showDetail = that.showAllIntents;
        if (message.custom_info !== undefined) {
          try {
            message.custom_info_parsed = JSON.parse(message.custom_info);
          } catch (e) {
            message.custom_info_parsed = {};
          }
        } else {
          message.custom_info_parsed = {};
        }
        if (message.analysis && message.analysis.length > 0) {
          message.custom_infoA = JSON.parse(message.analysis[0].custom_info);
          message.mainParameter = message.analysis[0].mainParameter;
          message.confidenceScore = _.pluck(message.analysis, 'rating')[0].toFixed(2);
          const { parametersParsed } = message.custom_infoA;
          if (parametersParsed !== undefined) {
            message.parametersParsed = _.values(parametersParsed);
            message.showParameters = true;
          } else {
            message.showParameters = false;
          }
          message.analysis = that.jsonParser(message.analysis);
          _.map(message.analysis, (analys) => {
            analys.custom_infoA = that.jsonParser(analys.custom_info);
            if (analys.msgtype == 'text' || analys.msgtype == 'SCAN' || analys.msgtype == 'subscribe') {
              analys.contentArr = [];
              const _isFULFILLMENTMSG = analys.content.includes('{FULFILLMENTMSGDELIMITER}');
              if (_isFULFILLMENTMSG == true) {
                const _FULFILLMENTMSGARRAY = analys.content.split('{FULFILLMENTMSGDELIMITER}');
                analys.contentArr = _FULFILLMENTMSGARRAY;
              } else {
                analys.contentArr[0] = analys.content;
              }
            } else if (analys.msgtype == 'card') {
              const mediaMsgArr = [];
              const parsed_content = JSON.parse(analys.content);
              for (const key in parsed_content) {
                _.forEach(parsed_content[key], (mediaMsg) => {
                  mediaMsgArr.push(mediaMsg);
                });
              }
              analys.mediaMsgArr = mediaMsgArr;
            } else if (analys.msgtype == 'image') {
              analys.parsed_content = JSON.parse(analys.content);
            } else if (analys.msgtype == 'quick') {
              analys.parsed_content = JSON.parse(analys.content);
              const quickReplyArr = [];
              const parsed_content = JSON.parse(analys.content);
              for (const key in parsed_content) {
                _.forEach(parsed_content[key], (reply) => {
                  quickReplyArr.push(reply);
                });
              }
              analys.quickReplyArr = quickReplyArr;
            } else if (analys.msgtype == 'multi') {
              return that.parseMultiMessages(analys);
            }
            return analys;
          });
        }
        if (!message.reviewStatus) {
          message.reviewStatus = 'unflagged';
        }
        return message;
      });
      that.showMessageLoading = false;
    },
    parseMultiMessages(analys) {
      const that = this;
      analys.parsed_content = that.jsonParser(analys.content);
      if (Array.isArray(analys.parsed_content)) {
        _.forEach(analys.parsed_content, (multiMessage, mindex) => {
          if (multiMessage.msgtype == 'text') {
            multiMessage.contentArr = [];
            if (multiMessage.content !== undefined) {
              const _isFULFILLMENTMSG = multiMessage.content.includes('{FULFILLMENTMSGDELIMITER}');
              if (_isFULFILLMENTMSG == true) {
                const _FULFILLMENTMSGARRAY = multiMessage.content.split('{FULFILLMENTMSGDELIMITER}');
                multiMessage.contentArr = _FULFILLMENTMSGARRAY;
              } else {
                multiMessage.contentArr[0] = multiMessage.content;
              }
            }
            return multiMessage;
          } if (multiMessage.msgtype == 'card') {
            multiMessage.mediaMsgArr = that.jsonParser(multiMessage.content);
            return multiMessage;
          } if (multiMessage.msgtype == 'image') {
            multiMessage.parsed_content = that.jsonParser(multiMessage.content);
            return multiMessage;
          }
          return multiMessage;
        });
      } else {
        console.log('not array  ', analys.content);
      }
      return analys;
    },
    jsonParser(obj) {
      try {
        return JSON.parse(obj);
      } catch (err) {
        return JSON.parse(JSON.stringify(obj));
      }
    },
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        that.isCall = false;
        if (date.start == date.end) {
          // if both date are same then just get data per hour for that date
          that.getSingleDateData({
            start_time: date.start,
            end_date: date.end,
          });

          that.updateTrendsData({
            aiAccount: that.currentAIAccount.id,
            startdate: date.start,
            enddate: date.end,
          });
        } else {
          // if both date are not same do a normal api call
          const data = {
            aiAccount: that.currentAIAccount.id,
            startdate: date.start,
            enddate: date.end,
            from: 'onDateUpdate',
          };
          that.updateAISesions(data);
          that.updateTrendsData(data);
        }
      } else {
        console.log('invalid date');
      }
    },
    updateAISesions(data) {
      const that = this;
      if (!that.isCall) {
        that.showChart = true;
        that.showInnerLoading = true;
        that.isCall = true;
        that.requestAISessionsAction(data).then((res) => {
          if (res.result.length > 0) {
            that.showChart = true;
            that.sessions_data = res.orderedArr;
            setTimeout(() => {
              that.arrangeData();
            });
          } else {
            that.showChart = false;
          }
        }).catch((err) => {
          that.showChart = false;
        }).finally(() => {
          setTimeout(() => {
            that.showInnerLoading = false;
            that.isCall = false;
          }, 1000);
        });
      }
    },
    updateTrendsData(data) {
      const that = this;
      that.showChart = true;
      that.showInnerLoading = true;
      if (that.chart) {
        that.chart.dispose();
      }
      this.requestAITrendsSessions(data).then((res) => {
        if (res.result.length > 0) {
          const response = res.result;
          that.unique_dates = res.unique_output;
          const colorSet = new am4core.ColorSet();
          colorSet.saturation = 0.5;
          const category = 1;
          that.Serpentine_data = _.map(response, (item, index) => {
            const data = {
              category: Math.floor(Math.random() * 6) + 1,
              start: item.start,
              end: item.end,
              id: item.id,
              startDTime: item.startDTime,
              endDTime: item.endDTime,
              color: colorSet.getIndex(Math.floor(Math.random() * 15) + 1),
            };
            return data;
          });
          that.showChart = true;
          setTimeout(() => {
            that.generateSerpentineChart();
          });
        } else {
          setTimeout(() => {
            that.showInnerLoading = false;
            that.showChart = false;
          }, 100);
        }
      })
        .finally(() => {
          setTimeout(() => {
            that.showInnerLoading = false;
          }, 2000);
        });
    },
    getSingleDateData(obj) {
      const that = this;
      if (!that.isCall) {
        that.isCall = true;
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: obj.start_time,
          enddate: obj.end_date,
        };
        if (this.chart) {
          this.chart.dispose();
        }
        if (this.heatChart) {
          this.heatChart.dispose();
        }
        that.showInnerLoading = true;
        that.requestAISessionsPerDay(data).then((res) => {
          if (res.result.length > 0) {
            that.showChart = true;
            that.sessions_data = res.orderedArr;
            setTimeout(() => {
              that.arrangeData();
            });
          } else {
            that.showChart = false;
          }
        }).catch((err) => {
          that.showChart = false;
        }).finally(() => {
          setTimeout(() => {
            that.showInnerLoading = false;
            that.isCall = false;
          }, 1000);
        });
      }
    },
    arrangeData() {
      const that = this;
      const { sessions_data } = that;
      const total_length = sessions_data.length;
      const session_duration_array = _.pluck(sessions_data, 'durationSeconds');
      const session_duration = _.filter(session_duration_array, (num) => num > 1);
      const longest_session = _.max(session_duration);
      const shortest_session = _.min(session_duration);
      const userOpenIds = _.pluck(sessions_data, 'userOpenId');
      const userOpenIdsUniq = _.uniq(userOpenIds);
      that.noOfuserServed = userOpenIdsUniq.length;
      const seconds_sum = _.reduce(session_duration, (memo, num) => memo + num, 0);
      that.avgDuration = that.toHHMMSS(Math.floor(seconds_sum / total_length), 'string');
      that.totalDuration = that.toHHMMSS(Math.floor(seconds_sum ), 'string');
      that.shortestDuration = that.toHHMMSS(shortest_session, 'string');
      that.longestDuration = that.toHHMMSS(longest_session, 'string');
      const groupByDay = _.groupBy(sessions_data, (obj) => obj.weekDay);
      const groupBySeconds = _.groupBy(sessions_data, (obj) => obj.durationSeconds);
      const byHourStats = [];
      for (const seconds in groupBySeconds) {
        const value = groupBySeconds[seconds].length;
        byHourStats.push({ seconds: `${seconds}s`, value });
      }
      that.arranged_data = byHourStats;
      setTimeout(() => {
        that.generateSerpentineChart();
      }, 100);
    },
    onAccountUpdate(account) {
      const that = this;
      const data = {
        aiAccount: account.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
        from: 'onAccountUpdate',
      };
      that.isCall = false;
      that.updateAISesions(data);
      that.updateTrendsData(data);
    },
    isValid(value) {
      if (
        value === undefined
        || value === null
        || (typeof value === 'object' && Object.keys(value).length === 0)
        || (typeof value === 'string' && value.trim().length === 0)
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    showChart(newVal) {
      const that = this;
      if (newVal == false) {
        if (that.chart) {
          that.chart.dispose();
        }
      }
    },
    darkTheme(){
      this.generateSerpentineChart()
    }
  },
  mounted() {
    const that = this;
    const data = {
      aiAccount: that.currentAIAccount.id,
      startdate: that.getFilterDate.start,
      enddate: that.getFilterDate.end,
      from: 'mounted',
    };
    if (data.startdate == data.enddate) {
      that.getSingleDateData({
        start_time: data.startdate,
        end_date: data.enddate,
      });
      that.updateTrendsData(data);
    } else {
      that.updateAISesions(data);
      that.updateTrendsData(data);
    }
  },
  created() {
    eventBus.$on('updateDate', this.onDateUpdate);
    eventBus.$on('updateAIAccount', this.onAccountUpdate);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
};
